import React  from 'react'
import { Link } from 'react-router-dom';
import AccommodationImg from '../../assets/images/COSMOPOLITAN UNIVERSITY 071.jpg'
import Bredcom from "../../pages/Bredcom/bredcom";
import {
    currencyConverter, currencyConverterDollar,
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";

function Accommodation() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Accommodation"/>

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__post-thumb">
                                    <img src={AccommodationImg} alt="" />
                                </div>
                                <div className="bsingle__content">
                                    <h3>
                                        Accommodation at Cosmopolitan University
                                    </h3>
                                    <div style={{textAlign: "justify"}}>
                                        <p>Cosmopolitan University is committed to providing a comfortable and supportive living environment for our students. We understand that finding suitable accommodation plays a crucial role in students overall university experience. Therefore, we offer a range of accommodation options to meet the diverse needs and preferences of our students.
                                        </p>
                                        <p>
                                            <strong>On-Campus Accommodation:</strong>
                                            Our on-campus accommodation provides convenience, community, and a truly immersive university experience. These residences are located within close proximity to academic buildings and offer a safe and inclusive environment for students. Features of our on-campus accommodation may include:
                                        </p>
                                        <ol>
                                            <li>Fully Furnished Rooms: Each room is equipped with essential furniture items, including a bed, study desk, chair, and storage facilities, ensuring a comfortable living space for students.
                                            </li>
                                            <li>Shared Facilities: Common areas such as kitchens, lounges, and laundry facilities are provided to foster social interaction and create a sense of community among residents.</li>
                                            <li>Wi-Fi Connectivity: High-speed internet connectivity is available throughout the accommodation, allowing students to stay connected with their studies and loved ones.
                                            </li>
                                            <li>24/7 Security: The safety and security of our students are of utmost importance. Our on-campus accommodation is equipped with 24/7 security personnel and surveillance systems to ensure a secure living environment.
                                            </li>
                                        </ol>
                                        <p>
                                            <strong>Off-Campus Accommodation:</strong>
                                            For students who prefer more independent living or have specific accommodation requirements, Cosmopolitan University can assist in finding suitable off-campus housing options. Our dedicated accommodation services team can provide guidance and support in securing private rentals or connecting with trusted accommodation providers in the local area.
                                        </p>
                                        <ol>
                                            <li>Student Support:
                                                At Cosmopolitan University, we understand that transitioning to university life and living away from home can be an exciting yet challenging experience. Our student support services team is available to assist you throughout your accommodation journey. Whether you need assistance with room allocations, maintenance requests, or general advice on living in the area, our team is here to help.
                                            </li>
                                            <li>Costs and Application Process:
                                                Detailed information about accommodation costs, application procedures, and deadlines can be found on our website or obtained by contacting our accommodation services office. We encourage students to apply for accommodation well in advance to secure their preferred option.
                                            </li>
                                        </ol>
                                        <p>Please note that while we strive to accommodate all students who apply for on-campus housing, availability may be limited. Therefore, we recommend early application to increase your chances of securing on-campus accommodation.
                                        </p>
                                        <p>Living in university accommodation provides more than just a place to sleep—it offers opportunities for personal growth, lasting friendships, and a supportive community. At Cosmopolitan University, we are dedicated to ensuring that your accommodation experience contributes to a fulfilling and memorable university journey.</p>
                                        <p>
                                            We look forward to welcoming you to our vibrant and inclusive accommodation community at Cosmopolitan University.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Hostel Fees (Per Semester)</h2>
                                    <div className="textwidget custom-html-widget">
                                        <ul>
                                            <table className="table table-responsive-md table-striped">
                                                <tbody>
                                                <tr>
                                                    <td><strong>Local</strong></td>
                                                    <td><strong>Intl.</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>N350,000</td>
                                                    <td>$500</td>
                                                </tr>

                                                </tbody>
                                            </table>

                                        </ul>
                                    </div>
                                </section>
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Accommodation
